:root {
  --dark-action-color: #f8f9fa;
  --light-action-color: #212529;
  --selected-action-color-1: #f00;
  --selected-action-color-2: #ff8700;
  --selected-action-color-3: #ffd300;
  --selected-action-color-4: #a1ff0a;
  --selected-action-color-5: #0aff99;
  --selected-action-color-6: #0aefff;
  --selected-action-color-7: #147df5;
  --selected-action-color-8: #580aff;
  --selected-action-color-9: #be0aff;
  --selected-design-font:
    "Ubuntu",
    ui-sans-serif,
    system-ui;
  --selected-font:
    "Ubuntu",
    ui-sans-serif,
    system-ui,
    -system-ui,
    -apple-system,
    blinkmacsystemfont,
    roboto,
    helvetica,
    arial,
    sans-serif,
    "Apple Color Emoji";
  --selected-error-color: orangered;
  --selected-info-color: mediumseagreen;
  --font-bold: 800;
  --font-bolder: 500;
  --font-regular: 300;
  --font-size-large: calc(var(--brand-font-size) + 1rem + 0.4vw);
  --font-size-medium: calc(var(--brand-font-size) + 0.7rem + 0.3vw);
  --font-size-small: calc(var(--brand-font-size) + 0.6rem + 0.3vw);
}

p {
  padding-bottom: 0.5rem;
}

.sun-editor {
  border: 0 !important;
  border-radius: 5px !important;
}

.sun-editor .se-toolbar {
  outline: 0 !important;
}

.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  font-family: var(--selected-font-family) !important;
  font-size: var(--font-size-medium) !important;
  color: var(--selected-font-color) !important;
  background-color: var(--selected-pure-color) !important;
  background-color: var(--selected-background-color) !important;
  min-height: 20rem !important;
  width: 100% !important;
  border: 1px solid var(--selected-font-color) !important;
  border-radius: 5px !important;

  &:active,
  &:focus {
    border: 1px solid var(--selected-action-color) !important;
  }
}

.se-toolbar.sun-editor-common {
  background-color: var(--dark-font-color) !important;
}

.se-resizing-bar.sun-editor-common {
  min-height: 0 !important;
  border: 0 !important;

  & > :nth-child(1) {
    font-size: 0 !important;
  }
}

.custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;

  @media (--viewport-s) {
    height: 100vh;
  }
}

.custom-scroll::-webkit-scrollbar {
  @media (--viewport-s) {
    scrollbar-width: 6px;
    width: 6px;
    height: 5px;
  }
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: var(--selected-pure-color);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--selected-action-color);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: var(--selected-scrollbar-margin-top, 0);
}

.custom-scroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: var(--selected-scrollbar-margin-bottom, 0);
}

.swiper-slide {
  margin-bottom: 2rem !important;
}
